@import "../../styles/base.scss";

@include block("bulletin-page") {

  @include element("back") {
    margin: 10px 0;
    cursor: pointer;
    font-size: 12px;
    padding: 6px 0 1px;
    line-height: 24px;
    border-bottom: 1px solid transparent;

    color: #8b9bad;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover {
      cursor: pointer;
      border-bottom: 1px solid #8b9bad;
    }
  }

}
