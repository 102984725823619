@import "../../styles/base.scss";
@import "../../styles/search-ui-v13s-theme/modules/variables.scss";

@include block("footer") {
  padding: 60px;
  background: #fafafb;

  @include element("wrapper") {
    max-width: $maxWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include element("company") {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  @include element("copyright") {
    max-width: 400px;
    font-size: 14px;
    color: #a0a0a0;
    margin-left: 40px;
    margin-right: 40px;
  }

  @include element("logo") {
    width: 120px;
  }

  @include element("columns") {
    display: flex;
    flex-direction: row;
  }
  @include element("column") {
    min-width: 200px;
    line-height: 28px;
    div {
      font-weight: 600;
      margin-bottom: 10px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    a {
      text-decoration: none;
      color: initial;
    }
  }

  @media (max-width: $sidebarBreakpoint) {
    @include element("wrapper") {
      display: block;
    }
    @include element("copyright") {
      margin-bottom: 20px;
    }
    @include element("columns") {
      display: block;
    }
    @include element("column") {
      margin-bottom: 20px;
    }
  }
}


