@include block('sorting') {
  @include themeTypography;
  display: inline-block;
  width: 100%;

  @include element('label') {
    font-size: $sizeM;
    color: $facetLabel;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
