@import "../../styles/base.scss";

@include block("contact-page") {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include element("body") {
    padding: 14px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;

    >h2 {
      margin-bottom: 40px;
    }

    ul {
      max-width: 720px;
      margin: 0 auto;
    }
    li {
      margin-bottom: 20px;
    }
    a {
      color: #668bfa;
      text-decoration: none;
    }
  }

  @include element("form") {
    padding: 40px;
    margin: 50px auto;
    width: 350px;
    color: rgb(0, 0, 0);
    background: transparent;
    border: none;
    border-radius: 8px;
    box-shadow: inset 0px 0px 0px 1px, 0px 1px 2px 0px rgba(0, 0, 0, 0.17);

    >label {
      font-size: 14px;
    }
    >input {
      border-radius: 3px;
      border: 0;
      border-bottom: 1px solid #E7E7EB;
      height: 42px;
      font-size: 18px;
      width: 100%;
      margin-bottom: 12px;
    }
    >input:focus-visible, >input:focus {
      outline: none !important;
      border-color: #E7E7EB;
    }

    >textarea {
      resize: none;
      width: 100%;
      margin-bottom: 12px;
      border: 0;
      border-bottom: 1px solid #E7E7EB;
      outline: none !important;
      min-height: 80px;
      font-size: 18px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    }
  }

  button {
    text-align: center;
    margin: 0 auto;
    display: block;
    background: #FFF;
    border: 1px solid #E7E7E7;
    padding: 16px 32px;
    border-radius: 5px;
    cursor: pointer;
  }
  button:hover {
    background: #F7F7F7;
  }
  button:active {
    background: #E7E7EB;
  }

  @include element("success") {
    margin: 32px;
    padding: 16px 32px;
    border-radius: 5px;
    width: 450px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .v13s-button {
      margin-top: 40px;
      text-decoration: none;
    }
  }
}
