@import "../../styles/base.scss";

@include block("api-page") {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include element("body") {
    padding: 14px;
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    >h2 {
      margin-bottom: 0;
    }

    p{
      max-width: 720px;
    }
  }
}
