@import "../../../styles/base.scss";
@import "../../../styles/search-ui-v13s-theme/modules/variables.scss";

@include block("main-page-search") {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  flex: 1;
  align-items: center;
  justify-content: center;
  max-height: calc(100% - 80px);
  text-align: center;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 120px 0 160px;

  h1 {
    font-size: 32px;
  }
  h2 {
    margin-bottom: 100px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  @include element("search") {

    .button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: $sidebarBreakpoint) {
    display: block;
    padding: 60px 0 120px;
  }
}
