@import "../../styles/base.scss";

@include block("search-result") {
  display: grid;
  grid-template-columns: 100px auto 80px;
  grid-template-areas:
    "score title       chevron"
    "score description chevron"
    "score details     chevron";

  cursor: pointer;
  text-decoration: none;
  color: #000;

  @include element("title") {
    grid-area: title;
    margin-bottom: 12px;
  }

  @include element("description") {
    grid-area: description;
  }

  @include element("details") {
    grid-area: details;
    font-size: 0.8em;
    color: $resultDetailsTextColor;
  }

  @include element("score") {
    grid-area: score;
    place-self: center;

    --element-collor: #{$linkColor};

    border: 2px solid var(--element-collor);
    border-radius: 4px;
    color: var(--element-collor);
    font-size: 1.9em;
    text-align: center;
    height: 55px;
    line-height: 55px;
    width: 55px;
    box-sizing: content-box;

    @include modifier("0") {
      --element-collor: #{$cvsScore0};
    }

    @include modifier("1") {
      --element-collor: #{$cvsScore1};
    }

    @include modifier("2") {
      --element-collor: #{$cvsScore2};
    }

    @include modifier("3") {
      --element-collor: #{$cvsScore3};
    }

    @include modifier("4") {
      --element-collor: #{$cvsScore4};
    }

    @include modifier("5") {
      --element-collor: #{$cvsScore5};
    }

    @include modifier("6") {
      --element-collor: #{$cvsScore6};
    }

    @include modifier("7") {
      --element-collor: #{$cvsScore7};
    }

    @include modifier("8") {
      --element-collor: #{$cvsScore8};
    }

    @include modifier("9") {
      --element-collor: #{$cvsScore9};
    }
  }

  @include element("chevron") {
    grid-area: chevron;
    place-self: center;

    opacity: 0;
    transition: opacity 0.5s;
    height: 5px;
    width: 30px;
    position: relative;
    transform: rotate(-90deg);

    .v13s-search-result:hover & {
      opacity: 1;
      transition: opacity 0.2s;
    }

    &:before,
    &:after {
      content: "";
      height: 100%;
      width: 50%;
      position: absolute;
      top: 0;
      background: $linkColor;
    }
    &:before {
      left: 0;
      transform: skew(0deg, 40deg);
    }
    &:after {
      right: 0;
      transform: skew(0deg, -40deg);
    }
  }
}
