@import "../../styles/base.scss";
@import "../../styles/search-ui-v13s-theme/modules/variables";

@include block("bulletin-info") {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  padding: 24px;

  border: 1px solid #f0f0f0;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);

  @include element("title") {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 20px;
    color: $primaryColor;
  }

  @include element("labels") {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 10px 10px;
  }

  @include element("label") {
    font-size: 13px;
    color: #4f4f4f;
  }

  @include element("score") {
    color: $primaryColor;
    font-weight: 700;

    @include modifier("0") {
      color: $cvsScore0;
    }

    @include modifier("1") {
      color: $cvsScore1;
    }

    @include modifier("2") {
      color: $cvsScore2;
    }

    @include modifier("3") {
      color: $cvsScore3;
    }

    @include modifier("4") {
      color: $cvsScore4;
    }

    @include modifier("5") {
      color: $cvsScore5;
    }

    @include modifier("6") {
      color: $cvsScore6;
    }

    @include modifier("7") {
      color: $cvsScore7;
    }

    @include modifier("8") {
      color: $cvsScore8;
    }

    @include modifier("9") {
      color: $cvsScore9;
    }
  }

  @include element("description-label") {
    font-weight: 600;
  }

  @include element("description") {
    padding-left: 30px;
  }

  @include element("exploit") {
    border-radius: 4px;
    border: 1px solid #f0f0f0;;
    padding: 2px;
  }

  @include element("more-details") {
    margin: 60px auto;
    a {
      color: $primaryColor;
      text-transform: uppercase;
    }
  }
}
