@import "../../styles/base.scss";

@include block("button") {
  color: rgb(0, 0, 0);
  background: transparent;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0px 0px 1px, 0px 1px 2px 0px rgb(0 0 0 / 17%);
  padding: 8px 20px;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 1px, 0px 4px 10px 0px rgb(50 50 93 / 23%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  }

  @include modifier("black") {
    color: #fff;
    background: rgb(0, 0, 0);
  }
}
