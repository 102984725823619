// Block
@mixin block($block) {
  .v13s-#{$block} {
    @content;
  }
}

// Block Element
@mixin element($element) {
  &__#{$element} {
    @content;
  }
}

// Block Modifier
@mixin modifier($modifier) {
  &--#{$modifier} {
    @content;
  }
}
