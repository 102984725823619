html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@import "modules/variables";
@import "modules/mixins";
@import "layouts/layout";
@import "components/error-boundary";
@import "components/facets";
@import "components/multi-checkbox-facet";
@import "components/boolean-facet";
@import "components/single-links-facet";
@import "components/paging";
@import "components/paging-info";
@import "components/result";
@import "components/results-container";
@import "components/results-per-page";
@import "components/search-box";
@import "components/sorting";
@import "components/select";
