@import "../../styles/base.scss";
@import "../../styles/search-ui-v13s-theme/modules/variables.scss";

@include block("header-wrapper") {
  display: block;
}

@include block("header") {

  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 32px 24px;
  margin: 0 auto;
  line-height: 28px;
  max-width: $maxWidth;

  @include element("logo") {
    //margin-top: 10px;
  }

  @include element("content") {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include element("menu") {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 0 12px;
    }
    a {
      text-decoration: none;
      color: #6e6d7a;
      font-weight: 500;
    }
  }

  @media (max-width: $sidebarBreakpoint) {
    flex-direction: column;
    margin: 0;
    padding: 24px 24px;

    li {
      padding: 10px 5px;
    }
    .v13s-button {
      display: none;
    }
    @include element("menu") {
      flex-wrap: wrap;
    }
  }
}
