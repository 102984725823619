@import "base.scss";

html {
  //box-sizing: border-box;
}
*,
*:before,
*:after {
  //box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body { height: 100%; margin: 0; padding: 0; }

#root, {
  height: 100%;
}
