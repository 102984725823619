@include block("multi-checkbox-facet") {
  color: $facetLinkColor;
  font-size: 13px;
  margin: 8px 0;

  @include element("option-label") {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  @include element("option-input-wrapper") {
    flex: 1;
  }

  @include element("checkbox") {
    margin-right: $sizeS;
    cursor: pointer;
  }

  @include element("option-count") {
    color: #888888;
    font-size: 0.85em;
    margin-left: $sizeL;
  }
}

@include block("facet-view-more") {
  display: block;
  cursor: pointer;
  color: $linkColor;
  font-size: 13px;

  // Button reset
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  border: unset;
  padding: unset;
  background: unset;

  &:hover,
  &:focus {
    background-color: $facetSelectBackground;
    outline: $sizeXS solid $facetSelectBackground;
  }
}

@include block("facet-search") {
  margin: 6px 0px 0px 0px;

  @include element("text-input") {
    width: 100%;
    height: 100%;
    padding: 6px;
    margin: 0;
    font-family: inherit;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline: none;

    &:focus {
      border: 1px solid $linkColor;
    }
  }
}
