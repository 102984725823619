@import "../../styles/base.scss";

@include block("not-found-page") {
  @include element("label") {
    border-right: 5px solid $secondaryColor;
    padding-right: 20px;
    margin-right: 20px;
    font-size: 6vw;
  }
}
