@import "../../styles/base.scss";

@include block("main-page") {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include element("body") {
    padding: 14px;
  }
}
