@include block("paging") {

  list-style: none;
  display: flex;


  > li {
    margin: 0 4px;
    border: none;
    background: transparent;
    outline: none;
  }

  .rc-pagination-disabled {
    a {
      color: #ccc;
      opacity: 0.5;
    }
  }

  .rc-pagination-item {
    a {
      cursor: pointer;
      color: $linkColor;
      text-decoration: none;
    }

    &:hover {
      background: $pagingHoverBackground;
      a {
        color: $linkColor;
        text-decoration: none;
      }
    }
  }

  .rc-pagination-item-active {
    a {
      color: $facetLinkColor;
      font-weight: $fontWeightBold;
    }

    &:hover {
      background: transparent;
      cursor: not-allowed;

      a {
        color: $facetLinkColor;
        cursor: not-allowed;
      }
    }
  }

  .rc-pagination-next {
    &:hover {
      background: $pagingHoverBackground;
      a {
        color: $linkColor;
      }
    }
  }

  .rc-pagination-jump-next, .rc-pagination-jump-prev {
    margin: 0 8px;
  }

  .rc-pagination-jump-next:hover {
    background: $pagingHoverBackground;
    a {
      color: $linkColor;
    }
  }

  .rc-pagination-jump-next:hover:after {
    color: $linkColor;
    content: "\BB";
    font-size: 16px;
    line-height: $lineHeight;
  }

  .rc-pagination-jump-prev:hover {
    background: $pagingHoverBackground;
    a {
      color: $linkColor;
    }
  }

  .rc-pagination-jump-prev:hover:after {
    color: $linkColor;
    content: "\AB";
    font-size: 16px;
    line-height: $lineHeight;
  }
}
