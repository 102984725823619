@include block("single-option-facet") {
  font-size: 13px;
  margin: $sizeS 0;
  padding: 0;
  list-style: none;

  @include element("item") {
    display: flex;
    justify-content: space-between;
  }

  @include element("link") {
    color: $facetLinkColor;
    position: relative;
    text-decoration: none;
    list-style: none;
    padding: 0;
    font-weight: bold;

    &:after {
      content: "";
      opacity: 0;
      position: absolute;
      top: -1px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 2px);
      background: rgba(37, 139, 248, 0.08);
      pointer-events: none;
    }

    &:focus {
      color: $linkColor;
      font-weight: bold;
      outline: none;
    }

    &:hover {
      color: $linkColor;
      font-weight: bold;

      &:after {
        opacity: 1;
      }
    }
  }

  @include element("selected") {
    font-weight: 900;
    list-style: none;

    a {
      font-weight: 100;
      padding: 0 2px;
    }
  }

  @include element("remove") {
    color: #666;
    margin-left: 10px;
  }
}
