@import "../../styles/base.scss";

@include block("tos-page") {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include element("body") {
    padding: 14px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;

    >h2 {
      margin-bottom: 40px;
    }

    ul {
      list-style-type: decimal;
    }
    li {
      margin: 10px 0;
    }
  }

}
