@include block('results-per-page') {
  @include themeTypography;
  color: $metaTextColor;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 100%;

  @include element('label') {
    margin-right: $sizeS;
  }

  .sui-select__control {
    align-items: center;

    input {
      position: absolute;
    }
  }
}
