@import "../../styles/base.scss";

@include block("search-field") {

  form {
    min-width: 700px;
  }

  @media (max-width: 700px) {
    form {
      min-width: initial;
      width: 100%;
    }
  }
}
