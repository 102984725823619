@import "../../../styles/base.scss";
@import "../../../styles/search-ui-v13s-theme/modules/variables.scss";

@include block("main-page-benefits") {

  margin: 0 auto;
  max-width: $maxWidth;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 100px;

  >div {
    max-width: 220px;
  }

  @media (max-width: $sidebarBreakpoint) {
    flex-wrap: wrap;
    >div {
      max-width: 180px;
      margin-bottom: 40px;
    }
  }
}
