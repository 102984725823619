@include block('result') {
  @include themeTypography;
  list-style: none;
  padding: $sizeL 0;
  text-decoration: none;
  display: block;
  border: 1px solid #f0f0f0;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  overflow: hidden;

  & + & {
    margin-top: $sizeXL;
  }

  em {
    position: relative;
    color: $textMatchColor;
    font-weight: $fontWeightBold;
    font-style: inherit;

    &:after {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      background: rgba(37, 139, 248, 0.08);
      pointer-events: none;
    }
  }

  @include element('header') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  @include element('title') {
    font-size: 1.2em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $fontWeightNormal;
    color: $black;
    text-decoration: none;
  }

  @include element('title-link') {
    color: $linkColor;
    text-decoration: none;
  }

  @include element('key') {
    font-family: monospace;
    font-weight: $fontWeightNormal;
    font-size: 14px;
    flex: 0 1 50%;
    color: $resultKeyTextColor;

    &:before {
      content: '"';
    }
    &:after {
      content: '": ';
    }
  }

  @include element('value') {
    font-weight: $fontWeightNormal;
    font-size: 14px;
  }

  @include element('version') {
    font-size: 12px;
    display: inline;
    vertical-align: bottom;
  }

  @include element('license') {
    font-size: 12px;
    color: #999999;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 3px;
    line-height: 1;
    padding: 4px 4px 3px 4px;
  }

  @include element('body') {
    line-height: 1.5;
    margin-top: 0;
    display: flex;

    p {
      margin: 0;
    }
  }

  @include element('image') {
    padding-top: $sizeM;
    padding-left: $sizeL;
    flex-basis: 220px;

    img {
      max-width: 100%;
      display: block;
      height: auto;
    }
  }

  @include element('details') {
    flex: 1;
    list-style: none;
    padding: $sizeM $sizeL;
    margin: 0;
  }
}
