// General Colors
$linkColor: #5E3AEE;
$primaryColor: #5E3AEE;
$secondaryColor: #4f4f4f;

// CVS score colors
$cvsScore0: #21bf73;
$cvsScore1: #21bf73;
$cvsScore2: #f6c135;
$cvsScore3: #f6c135;
$cvsScore4: #f6c135;
$cvsScore5: #f78e4e;
$cvsScore6: #f78e4e;
$cvsScore7: #f66072;
$cvsScore8: #f66072;
$cvsScore9: #f66072;

// Result Colors
$resultDetailsTextColor: #a3a3a3 !default;
