@include block("facet") {
  @include themeTypography;
  // fieldset reset
  margin: 0;
  padding: 0;
  border: 0;

  & + & {
    margin-top: $sizeXL;
  }

  .sui-sorting + & {
    margin-top: $sizeXL;
  }

  @include element("title") {
    text-transform: uppercase;
    font-size: 12px;
    color: #8b9bad;
    letter-spacing: 1px;
    // legend reset
    padding: 0;
  }

  @include element("list") {
    line-height: 1.5;
    font-size: 13px;
    margin: $sizeS 0;
    padding: 0;
  }

  @include element("count") {
    color: $facetCountColor;
    font-size: 0.85em;
    margin-left: 20px;
    display: inline-block;
    padding-top: 2px;
  }
}
