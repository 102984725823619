@import "../../styles/base.scss";
@import "../../styles/search-ui-v13s-theme/modules/variables.scss";

@include block("search-header") {
  display: flex;
  align-items: center;

  @include element("logo") {
    padding-right: 24px;
    a {
      cursor: pointer;
    }
  }

  @include element("search") {
    flex-grow: 1;
  }

  @media (max-width: $sidebarBreakpoint) {
    display: block;
    margin: 0;
    li {
      padding: 20px 6px;
    }

    @include element("search") {
      flex-grow: 0;
    }
  }
}
